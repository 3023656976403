import React from 'react';

import Header from 'components/shopHeader/Header';
import Preloader from 'components/shared/preloader/Preloader';

import styles from './Layout.module.scss';

interface ILayoutProps {
  children: React.ReactNode;
  goBack(): void;
  loading?: boolean;
  openLocationDialog?(): void;
}

const Layout: React.FC<ILayoutProps> = ({
  children,
  goBack,
  loading = false,
  openLocationDialog,
}) => (
  <section className={styles.layout}>
    <Preloader isActive={loading} />
    <Header
      className={styles.layoutHeader}
      goBack={goBack}
      openLocationDialog={openLocationDialog}
    />
    {children}
  </section>
);

export default Layout;
