import { PlaceService, MenuService } from '@legalize/sdk';

import { Observer } from 'core/framework';
import { DeliveryServiceTypes, DeliveryDialogDeliverTypes } from '../enums';
import { getPlaceDetailsByZipCode } from 'services';
import * as clicApi from 'services';
import { marketId, brandIds, categoryClickIds, legalizeApiUrl } from 'services/config';

class DeliveryModel extends Observer implements Delivery.Model {
  private deliveries: Delivery.DeliveryItem[];
  private placeService: PlaceService;
  private menuService: any;

  constructor() {
    super();

    this.placeService = new PlaceService(legalizeApiUrl);

    this.deliveries = [
      this.buildDeliveryService_deliveryService(),
      this.buildDeliveryService_byAtStore(0),
    ];
    this.menuService = new MenuService(legalizeApiUrl);
  }

  public getDeliveries() {
    return this.deliveries;
  }

  public getDeliveryDestinations(): Delivery.DeliveryDestination[] {
    return [{ type: DeliveryDialogDeliverTypes.deliverTo, isAvailable: true }];
  }

  public updateDeliveries(
    destinationType: DeliveryDialogDeliverTypes,
    zipCode: number,
  ): Promise<Delivery.UpdateDeliveriesResult> {
    return new Promise((resolve, reject) => {
      this.getPlaceDetails(destinationType, zipCode)
        .then((placeDetails) => {
          this.placeService
            .getTerritoriesCountByPlaceId(placeDetails.getPlaceId())
            .then((territoriesCount) => {
              let firstDelivery = this.buildDeliveryService_deliveryService();

              if (Boolean(territoriesCount)) {
                firstDelivery = this.buildDeliveryService_deliverByClick();
              }

              this.deliveries = [
                firstDelivery,
                this.buildDeliveryService_byAtStore(placeDetails.getNearbyStoresCount()),
              ];

              resolve({ deliveries: this.deliveries, placeDetails });
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  private getPlaceDetails(destinationType: DeliveryDialogDeliverTypes, zipCode: number) {
    switch (destinationType) {
      case DeliveryDialogDeliverTypes.deliverTo:
      default:
        return getPlaceDetailsByZipCode(zipCode);
    }
  }

  private buildDeliveryService_deliverByClick() {
    return {
      id: DeliveryServiceTypes.deliverByClick,
      description: 'Free Ship<br>for Order from $100',
    };
  }

  private buildDeliveryService_deliveryService() {
    return {
      id: DeliveryServiceTypes.deliveryService,
      description: 'Get CLICK delivered<br>to your door',
    };
  }

  private buildDeliveryService_byAtStore(storesCount = 0) {
    return {
      id: DeliveryServiceTypes.byAtStore,
      description: `We've found<br>${storesCount} Stores nearby`,
    };
  }

  public loadRetailersNear(data: any) {
    return new Promise((resolve) =>
      clicApi
        .getDispensaries('', `lat=${data.lat}&lng=${data.lng}&maxDistance=100`)
        .then((response) => response.json())
        .then((resultData) => {
          resolve(resultData);
        })
        .catch(() => resolve([])),
    );
  }

  public checkDelivery(placeId: string) {
    return new Promise((resolve) =>
      this.menuService
        .getGroupsByBrandAndCategoryIds(marketId, placeId, brandIds, categoryClickIds)
        .then((resultData: any) => {
          const products = [...(resultData[0]?.entity?.skus || [])];
          resolve(products.length > 0);
        })
        .catch(() => resolve([])),
    );
  }

  public getDetailsByPlaceId(placeId: string) {
    return this.placeService.getDetailsByPlaceId(placeId).then((resultData: any) => {
      return Promise.resolve(resultData?.entity || null);
    });
  }
}

export default DeliveryModel;
