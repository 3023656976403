import React from 'react';

import stylesModule from './Header.module.scss';

interface IHeaderProps {
  title: string;
}

const Header = ({ title }: IHeaderProps) => (
  <div className={stylesModule.header}>
    <div className={stylesModule.content}>
      <div className={stylesModule.title}>{title}</div>
    </div>
  </div>
);

export default Header;
