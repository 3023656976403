import _ from 'lodash';
import { useEffect } from 'react';
import { navigate } from '@reach/router';
import { Presenter } from 'core/framework';
import { DeliveryDialogDeliverTypes, DeliveryStages } from '../enums';
import PlaceDetailsEntity from 'core/entities/placeDetails/PlaceDetails';

class DeliveryPresenter extends Presenter<Delivery.Model, Delivery.StoreState, any> {
  private StorageService: StorageService;

  constructor(options: Delivery.PresenterOptions) {
    super(options);

    this.StorageService = options.StorageService;

    this.state = _.merge({}, this.store.getState(), this.StorageService.read());

    this.store.setState(this.state);

    this.StorageService.on('update', () => {
      this.store.setState(this.StorageService.read() as Delivery.StoreState);
    }).emit('subscribe');
  }

  public usePresenter(): Delivery.PresenterHook {
    const { state, dispatch } = this.store.useReducer();
    this.state = state;
    this.dispatch = dispatch;

    this.StorageService.write(state);

    useEffect(this.initialize, []);

    return {
      state: {
        ...state,
        placeDetails: new PlaceDetailsEntity(state.placeDetails),
        market: this.config.market,
      },
      navigateToPreviousPage: this.navigateToPreviousPage,
      navigateToDialog: this.navigateToDialog,
      navigateToDeliveries: this.navigateToDeliveries,
      navigateToCart: this.navigateToCart,
      navigateToNearestStores: this.navigateToNearestStores,
      changeDeliveryDestination: this.changeDeliveryDestination,
      setDialogZipCodeValue: this.setDialogZipCodeValue,
      closeDialog: this.closeDialog,
      loadRetailersNear: this.loadRetailersNear,
      checkDelivery: this.checkDelivery,
      setPlaceDetails: this.setPlaceDetails,
      getDetailsByPlaceId: this.getDetailsByPlaceId,
    };
  }

  private initialize() {
    this.setDeliveries();

    return this.destroy;
  }

  private destroy() {}

  private setDeliveries() {
    if (!this.state.deliveries.length) {
      this.dispatch.setDeliveries(this.model.getDeliveries());
    }
  }

  private navigateToPreviousPage() {
    navigate(-1);
  }

  private navigateToDialog() {
    this.dispatch.changeCurrentStage(DeliveryStages.dialog);
  }

  private navigateToCart() {
    navigate('/shop/cart');
  }

  private navigateToDeliveries() {
    this.dispatch.changeCurrentStage(DeliveryStages.delivery);
  }

  private navigateToNearestStores() {
    this.dispatch.changeCurrentStage(DeliveryStages.nearestStores);
  }

  private changeDeliveryDestination(activeDestinationType: DeliveryDialogDeliverTypes) {
    return () => {
      const { destinations } = this.state;
      const targetDestination = destinations.find(
        (destination) => destination.type === activeDestinationType,
      );

      if (targetDestination?.isAvailable && !targetDestination.isActive) {
        this.dispatch.setDeliveryDestinations({
          destinations: destinations.map((destination) => ({
            ...destination,
            isActive: destination.type === activeDestinationType,
          })),
          activeDestinationType,
          isZipCodeVisible: activeDestinationType === DeliveryDialogDeliverTypes.deliverTo,
        });
      }
    };
  }

  private setDialogZipCodeValue(event: React.SyntheticEvent) {
    this.dispatch.setDialogZipCodeValue((<HTMLInputElement>event.target).value || '');
  }

  private setPlaceDetails(placeDetails: Entities.PlaceDetailsRaw) {
    this.dispatch.setPlaceDetails(placeDetails);
    setTimeout(() => {
      this.checkDelivery();
    }, 100);
  }

  private closeDialog() {
    const { activeDestinationType, zipCodeValue } = this.state;

    this.dispatch.setLoading(true);

    this.model
      .updateDeliveries(activeDestinationType as DeliveryDialogDeliverTypes, Number(zipCodeValue))
      .then(({ deliveries, placeDetails }) => {
        this.dispatch.setDeliveries(deliveries);
        this.dispatch.setPlaceDetails(placeDetails.toJSON());
        this.navigateToDeliveries();
      })
      .catch((error) => {})
      .finally(() => this.dispatch.setLoading(false));
  }

  private loadRetailersNear(callback: (p: any) => void) {
    let placeDetails = this.state.placeDetails?.location;
    this.model
      .loadRetailersNear(placeDetails)
      .then(callback)
      .catch((error) => {});
  }

  private checkDelivery() {
    this.dispatch.setLoading(true);
    let placeId = this.state.placeDetails?.placeId;
    this.model
      .checkDelivery(placeId)
      .then((e) => {
        e ? this.navigateToDeliveries() : this.navigateToNearestStores();
        this.dispatch.setLoading(false);
      })
      .catch((error) => {});
  }

  private getDetailsByPlaceId(placeId: string) {
    this.model
      .getDetailsByPlaceId(placeId)
      .then((data: Entities.PlaceDetailsRaw) => {
        data && this.setPlaceDetails(data);
      })
      .catch((error) => {});
  }
}

export default DeliveryPresenter;
