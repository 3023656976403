import { PromotionType } from '@legalize/sdk/lib/enums/PromotionType';

export const applyPromotionToPrice = (sku: any) => {
  let newPrice = sku.price;

  (sku.promotions || []).forEach((promotion: any) => {
    switch (promotion.promotionType) {
      case PromotionType.AMOUNT_DISCOUNT:
        newPrice = sku.price - promotion.amount;
        break;
      case PromotionType.GIFT:
        break;
      case PromotionType.LOYALTY_POINTS:
        break;
      case PromotionType.PERCENT_DISCOUNT:
        newPrice = sku.price - (promotion.percent * sku.price) / 100;
        break;
      default:
        break;
    }
  });

  return { ...sku, newPrice };
};
