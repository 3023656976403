import React from 'react';

import CrossImageFile from 'images/cross.svg';

import styles from './Modal.module.scss';

interface IModalProps {
  show: boolean;
  onHide(): void;
  minPrice: number;
}

const Modal: React.FC<IModalProps> = ({ show, onHide, minPrice }) =>
  !show ? null : (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <div className={styles.body}>
          <div className={styles.headerBackground}>
            <div className={styles.modalTitle}>${minPrice} minimum is required on all orders</div>
            <button onClick={onHide} className={styles.closeButton}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );

export default Modal;
