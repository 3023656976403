import React, { useState } from 'react';
import { isNode } from 'browser-or-node';
import { Router } from '@reach/router';
import { navigate, graphql } from 'gatsby';
import { LoginCallback, Security } from '@okta/okta-react';

import MarketService from 'core/services/marketService/MarketService';
import authService from 'core/services/authService/AuthService';

import Login from 'modules/auth/login';
import SignUp from 'modules/auth/signup';
import SocialSignup from 'modules/auth/socialSignup';
import PrivateRoute from 'modules/auth/privateRoute';
import CompleteSignup from 'modules/auth/complateSignup';

import Cart from 'modules/shop/cart';
import Delivery from 'modules/shop/delivery';

import ShopAuthLayout from 'components/layouts/shopAuth/ShopAuthLayout';

interface IShopProps {
  data: {
    [imgKey: string]: {
      childImageSharp: {
        fluid: string;
      };
    };
  };
}

const ShopPage: React.FC<any> = () => {
  React.useEffect(() => {
    navigate('/shop/cart');
  }, []);

  return null;
};

const CartLogin: React.FC<any> = () => {
  React.useEffect(() => {
    navigate('/shop/cart?checkout=1');
  }, []);

  return null;
};

const Shop: React.FC<IShopProps> = ({ data }) => {
  const [loaded, setLoaded] = useState(false);
  const [market, setMarket] = useState({} as MarketEntity);

  React.useEffect(() => {
    MarketService.getInstance()
      .getMarket()
      .then((res) => {
        if (!res.isActive) {
          navigate('/');
        }
        if (res) {
          setMarket(res);
          setLoaded(true);
        }
      });
  }, []);

  return !loaded || isNode ? null : (
    <Security authService={authService.getInstance()}>
      <Router basepath="/shop">
        <ShopPage path="https://clickspray.com" />
        <LoginCallback path="/implicit/callback" />
        <Login
          path="/login"
          layoutComponent={ShopAuthLayout}
          signInLink="/shop/join"
          isAuthenticatedRedirectLink="/shop/cart?checkout=1"
        />
        <SignUp
          path="/signup"
          backLink="/shop/join"
          loginLink="/shop/login"
          complateRegistrationLink="/shop/cart-login"
          isAuthenticatedRedirectLink="/shop/cart?checkout=1"
        />
        <CompleteSignup
          path="/complete"
          backLink="/shop/join"
          signupLink="/shop/signup"
          complateRegistrationLink="/shop/cart"
        />
        <SocialSignup
          path="/join"
          loginLink="/shop/login"
          signUpLink="/shop/signup"
          layoutComponent={ShopAuthLayout}
          isAuthenticatedRedirectLink="/shop/signup?social_auth=1"
        />
        <Delivery path="/delivery" market={market} />
        <Cart path="/cart" market={market} background={data.cartBackground.childImageSharp.fluid} />
        <PrivateRoute
          path="/cart-login"
          loginRedirectLink="/shop/join"
          signupRedirectLink="/shop/signup"
          fullRegistrationComplatedRedirectLink="/shop/complete"
          component={CartLogin}
          background={data.cartBackground.childImageSharp.fluid}
        />
      </Router>
    </Security>
  );
};

export default Shop;

export const query = graphql`
  query {
    cartBackground: file(relativePath: { eq: "bg-cart.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
