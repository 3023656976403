import { Module } from 'core/framework';

import CartModel from './model/CartModel';
import CartView from './view/CartView';
import CartPresenter from './presenter/CartPresenter';
import CartStore from './presenter/CartStore';

const presenter = new CartPresenter({
  dependencies: {
    model: new CartModel(),
    store: new CartStore(),
  },
});

export default Module(presenter, CartView);
