import React from 'react';

import stylesModule from '../Cart.module.scss';

interface ICartFooterProps {
  total: any;
  calculating: boolean;
}

const renderPrice = (price: number) => (price / 100).toFixed(2);

const CartFooter: React.FC<ICartFooterProps> = ({ total, calculating }) => (
  <div className={stylesModule.cartFooter}>
    {calculating ? (
      <div className={stylesModule.loaderBlock}>
        <div className={stylesModule.loader}></div>
      </div>
    ) : (
      <div>
        <div>
          <span>Order Subtotal</span>
          <span className={stylesModule.totalPrice}>${renderPrice(total.originSubtotal)}</span>
        </div>
        {total.taxes.map((tax: any, index: number) => (
          <div key={index}>
            <span>{tax.tax.name}</span>
            <span className={stylesModule.totalPrice}>${renderPrice(tax.amount)}</span>
          </div>
        ))}
        {total.promotions.map((promotion: any, index: number) => (
          <div key={index}>
            <span>Discount</span>
            <span className={stylesModule.totalPrice}>-${renderPrice(promotion.discount)}</span>
          </div>
        ))}
        <div>
          <span>You Pay</span>
          <span className={stylesModule.totalPrice}>${renderPrice(total.total)}</span>
        </div>
      </div>
    )}
  </div>
);

export default CartFooter;
