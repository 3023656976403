import { time } from 'console';
import React from 'react';
import Timer from 'react-compound-timer';

import styles from './Timer.module.scss';

interface ITimerComponentProps {
  stop(): void;
  time: number;
}

const minutes = 15;
const one_minute = 60000;

const TimerComponent = ({ stop, time }: ITimerComponentProps) => {
  const start = minutes * one_minute - (new Date().getTime() - time);
  return (
    <div className={styles.timeBlock}>
      <Timer
        initialTime={start < minutes * one_minute ? start : 0}
        lastUnit="h"
        direction="backward"
        checkpoints={[
          {
            time: 0,
            callback: () => stop(),
          },
        ]}
      >
        {({ getTime }: { getTime: any }) => (
          <React.Fragment>
            <div className={styles.timeItem}>
              {getTime() / 60000 < 10 && '0'}
              <Timer.Minutes />
            </div>
            <div className={styles.timePoint}>:</div>
            <div className={styles.timeItem}>
              {getTime() / 1000 < 10 && '0'}
              <Timer.Seconds />
            </div>
          </React.Fragment>
        )}
      </Timer>
    </div>
  );
};

export default TimerComponent;
