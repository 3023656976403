import React from 'react';

import OrderAnalytics from 'core/framework/analytic/OrderFlow';

import DispensariesMap from 'components/shared/dispensaries-map/DispensariesMap';

import styles from './NearestStores.module.scss';

interface IDeliveryView {
  placeDetails: any;
}

const DeliveryView = ({ placeDetails }: IDeliveryView) => {
  let lat = placeDetails?.entity.location.lat;
  let lng = placeDetails?.entity.location.lng;
  return (
    <div>
      <div className={styles.title}>CLICK retailers near you</div>
      <DispensariesMap
        classes={styles}
        lat={lat}
        lng={lng}
        classNameActive={styles.activeDispansary}
        onRedirect={(name) => OrderAnalytics.redirectToRetailer(name)}
      />
    </div>
  );
};

export default DeliveryView;
