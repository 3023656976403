import React, { useState, useEffect } from 'react';
import BackgroundImage from 'gatsby-background-image';
import { NotificationManager } from 'react-notifications';
import { navigate } from 'gatsby';
import VizSensor from 'react-visibility-sensor';
import Preloader from 'components/shared/preloader/Preloader';
import { isBrowser } from 'browser-or-node';
import { useLocation } from '@reach/router';
import * as queryString from 'query-string';

import * as clicApi from '../../../../services';
import { OrderService } from '@legalize/sdk';
import { isProduction, legalizeApiUrl } from 'services/config';

import useAuth from 'hooks/useAuth';
import OrderAnalytics from 'core/framework/analytic/OrderFlow';

import Header from 'components/shopHeader/Header';
import Layout from 'components/layouts/main/Layout';
import CartHeader from './components/header/Header';
import Cart from './components/cart/Cart';
import DeliveryAddress from './components/delivery-address/DeliveryAddress';
import Information from './components/information/Information';
import Success from './components/success/Success';
import ClickInfo from './components/clickInfo/ClickInfo';
import TimerCart from './components/timerCart/TimerCart';

import styles from './CartView.module.scss';
import OrderFlow from 'core/framework/analytic/OrderFlow';

const STORAGE_CHECKOUT_ADDRESS_TOKEN = 'CHECKOUT_ADDRESS_TOKEN';
const STORAGE_CHECKOUT_TIMESLOT_TOKEN = 'CHECKOUT_TIMESLOT_TOKEN';

const CartView: React.FC<Cart.View> = ({ usePresenter }) => {
  const { state, ...actions } = usePresenter();
  const { background, market } = state;

  const {
    getQuantity,
    changeQuantity,
    navigateToDeliveries,
    getProducts,
    calcOrder,
    initPlace,
    addressCreate,
    orderCreate,
    getProductIds,
    getPlaceAutocomplete,
    getPlaceDetails,
    getCart,
    orderCheckout,
    getAuthorization,
    orderUpdate,
    findTimeslots,
    checkDiscount,
    setCrossSale,
  } = actions;

  const [products, setProduct] = useState([] as Cart.Product[]);
  const [quantity, setQuantity] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [showTimerCart, setShowTimerCart] = useState(false);
  const [isFirstPurchase, setIsFirstPurchase] = useState(false);
  const [userCart, setUserCart] = useState(undefined);
  const [depotId, setDepotId] = useState(0);
  const [placeId, setPlaceId] = useState('');
  const [scheduled, setScheduled] = useState([]);
  const [place, setPlace] = useState(null);
  const [orderNumber] = useState(0);
  const [vauchers, setVauchers] = useState([]);
  const [vouchersApply, setVouchersApply] = useState([]);
  const [crossSaleProducts, setCrossSaleProducts] = useState([]);
  const [time] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderPlacing, setOrderPlacing] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [isDefaultAddress, setIsDefaultAddress] = useState(false);
  const [minOrderPrice, setMinOrderPrice] = useState(0);

  const [total, setTotal] = useState({
    promotions: [] as any[],
    taxes: [] as any[],
    originSubtotal: 0,
    subtotal: 0,
    shipping: 0,
    total: 0,
  });

  const {
    state: { token, profile, isPending },
  } = useAuth();

  const onChangeQuantity = (data: any) => {
    changeQuantity(data);
    setQuantity(getQuantity());
  };

  const location = useLocation();

  useEffect(() => {
    if (isBrowser) {
      const { checkout: completeCheckout } = queryString.parse(location.search);
      if (completeCheckout) {
        let addressData: any;
        let timeslotData: any;
        try {
          addressData = window.localStorage.getItem(STORAGE_CHECKOUT_ADDRESS_TOKEN);
          addressData = addressData && JSON.parse(addressData);

          timeslotData = window.localStorage.getItem(STORAGE_CHECKOUT_TIMESLOT_TOKEN);
          timeslotData = timeslotData && JSON.parse(timeslotData);
        } catch (err) {
          console.error(err);
        }

        if (addressData && timeslotData && token) {
          setLoading(true);
          setOrderPlacing(true);
          getAuthorization(token as string, () => {
            onAddressCreate(addressData, timeslotData);
          });
        }
      }
    }
  }, [location, token]);

  useEffect(() => {
    setLoading(true);
    OrderFlow.viewСart();
  }, []);

  useEffect(() => {
    if (!isPending) {
      let placeId = initPlace();
      if (!placeId) {
        setIsDefaultAddress(true);
        placeId = market.defaultPlaceId;
      }
      setMinOrderPrice(market.businessAccounts?.[0].minOrderSum);
      setPlaceId(placeId);
      if (placeId) {
        if (token) {
          getAuthorization(token as string, () => {
            setDefaultData(placeId);
          });
        } else {
          setDefaultData(placeId);
        }
      }
    }
  }, [isPending]);

  const setDefaultData = (placeId: string) => {
    setLoading(true);
    getProducts((data) => {
      setProduct(data);
      setQuantity(getQuantity());
      setLoading(false);

      checkDiscount(placeId).then((res) => {
        setDepotId(res.depotId);

        if (res.skus[0]) {
          setIsFirstPurchase(!token || Boolean(res.skus[0].discount));
        }
      });
    });
    ({ placeId: placeId });

    getPlaceDetails(placeId, (res) => {
      setPlace(res);
    });
  };

  useEffect(() => {
    if (products.length && Object.keys(quantity).length !== 0) {
      setCalculating(true);
      calcOrder((res) => {
        if (res) {
          const shipping =
            res.taxes?.reduce(
              (accumulator: number, currentValue: any) => accumulator + currentValue.amount,
              0,
            ) || 0;

          setTotal({
            promotions: res.promotions || [],
            taxes: res.taxes || [],
            originSubtotal: res.originSubtotalPrice || 0,
            subtotal: res.subtotalPrice || 0,
            shipping: shipping,
            total: res.totalPrice || 0,
          });
          setDepotId(res.depotId);
        }

        setCalculating(false);
      }, token);
    } else {
      setTotal({
        promotions: [],
        taxes: [],
        originSubtotal: 0,
        subtotal: 0,
        shipping: 0,
        total: 0,
      });
    }
  }, [quantity, products]);

  useEffect(() => {
    if (placeId && depotId) {
      findTimeslots(placeId, depotId, (res: any) => setScheduled(res));
    }
  }, [placeId, depotId]);

  const onSubmit = (data: any, timeslotInfo: any) => {
    if (token) {
      onAddressCreate(data, timeslotInfo);
    } else {
      window.localStorage.setItem(STORAGE_CHECKOUT_ADDRESS_TOKEN, JSON.stringify(data));
      window.localStorage.setItem(STORAGE_CHECKOUT_TIMESLOT_TOKEN, JSON.stringify(timeslotInfo));
      navigate('/shop/cart-login');
    }
  };

  const calcSkus = () => {
    const quantity = getQuantity();
    const productIds = getProductIds();
    const skus: Cart.Order[] = [];
    for (const key in quantity) {
      const id = productIds[key];
      if (id && quantity[key]) {
        skus.push({
          skuId: +id,
          quantity: quantity[key],
        });
      }
    }
    return skus;
  };

  const onAddressCreate = (data: Cart.AddressCreate, timeslotInfo: any) => {
    setLoading(true);
    addressCreate(data, (address_responce: any) => {
      if (address_responce?.id) {
        getCart((cart_responce) => {
          if (!cart_responce) {
            onCreateOrder(+address_responce?.id, timeslotInfo);
          } else {
            const skus: Cart.Order[] = calcSkus();
            setUserCart({ addressId: +address_responce?.id, skus: skus, id: cart_responce });
            orderUpdate(cart_responce, +address_responce?.id, skus, [], (responce: any) =>
              onOrderCheckout(cart_responce, timeslotInfo, +address_responce, skus),
            );
          }
        });
      } else {
        setOrderPlacing(false);
        setLoading(false);
        NotificationManager.warning('warning');
      }
    });
  };

  const onCreateOrder = (addressId: number, timeslotInfo: any) => {
    const skus: Cart.Order[] = calcSkus();

    orderCreate(addressId, skus, ({ id }) => {
      if (id) {
        setUserCart({ addressId, skus, id });
        onOrderCheckout(id, timeslotInfo, addressId, skus);
      } else {
        setOrderPlacing(false);
        setLoading(false);
        NotificationManager.warning('warning');
      }
    });
  };

  const trackSurfsideEvent = async (orderId: number, skus: Cart.Order[]): Promise<void> => {
    const gingerToken = await clicApi
      .getLegalizeToken(token as string)
      .then((response) => response.json())
      .then((response) => response.token);
    const orderService = new OrderService(legalizeApiUrl, gingerToken as string);
    const order = await orderService.getOrderById(orderId);

    (window as any).surf(
      'addTransaction', // Provide Transaction details in an transactionFieldObject.
      orderId.toString(), // Transaction ID (string).
      '', // Transaction affiliation (string).
      order.originSubtotalPrice / 100, // Transaction revenue (string).
      order.taxes[0].entity.tax, // Product tax (string).
      '', // Transaction shipping (string).
      '', // Transaction coupon (number).
      '', // Transaction list (string).
      '', // Transaction step (number).
      '', // Transaction option (string).
      'USD', // Transaction currency (string).
    );
    for (const sku of skus) {
      const product = products.find(p => p.skuId === sku.skuId);

      (window as any).surf(
        'addProduct',
        sku.skuId, // Product ID
        product?.name, // Product name
        '', // Product list
        product?.brandName, // Product brand
        product?.categoryId, // Product category
        '', // Product variant
        product?.price / 100, // Product price
        sku.quantity, // Product quantity
        '', // Product coupon
        0, // Product position
        product?.currency, // Product currency
      );
    }

    (window as any).surf(
      'setCommerceAction',
      'purchase', // Purchase Action
    );
  }

  const onOrderCheckout = (
    orderId: number,
    timeslotInfo: any,
    addressResponse,
    skus: Cart.Order[],
  ) => {
    orderCheckout(orderId, timeslotInfo, (res) => {
      if (res) {
        if (res.success) {
          OrderAnalytics.placeOrder();
          trackSurfsideEvent(orderId, skus);

          setIsSuccess(true);

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

          window.localStorage.removeItem(STORAGE_CHECKOUT_ADDRESS_TOKEN);
          window.localStorage.removeItem(STORAGE_CHECKOUT_TIMESLOT_TOKEN);
        }
      } else {
        NotificationManager.warning('warning');
      }
      setOrderPlacing(false);
      setLoading(false);
    });
  };

  const onOrderUpdate = (skuId: number) => {
    if (userCart) {
      let is_add = false;
      const update_skus = userCart?.skus.map((item) => {
        if (item.skuId === skuId) {
          item.quantity = item.quantity + 1;
          is_add = true;
        }
        return item;
      });

      if (!is_add) {
        update_skus.push({ skuId: skuId, quantity: 1 });
      }

      const _voucher = vauchers[0] || {};
      const new_vouchersApply = [...vouchersApply, _voucher];

      orderUpdate(
        userCart.id,
        userCart.addressId,
        update_skus,
        new_vouchersApply,
        (responce: any) => {
          setUserCart({ addressId: userCart.addressId, skus: update_skus, id: userCart.id });

          const new_vouchers = vauchers.filter((item) => item.code !== _voucher.code);

          const res = responce.data.orderUpdate.order;

          setVauchers(new_vouchers);
          setVouchersApply(new_vouchersApply);

          setCrossSaleProducts([...crossSaleProducts, skuId]);
          setCrossSale({
            order: {
              addressId: userCart.addressId,
              skus: userCart.skus,
              id: userCart.id,
              total: {
                subtotal: res.subtotalPrice || 0,
                shipping: 0,
                total: res.totalPrice || 0,
              },
            },
            time,
            crossSaleProducts: [...crossSaleProducts, skuId],
            vauchers: new_vouchers,
            vouchersApply: new_vouchersApply,
          });
          setTotal({
            promotions: res.promotions || [],
            taxes: res.taxes || [],
            originSubtotal: res.originSubtotalPrice || 0,
            subtotal: res.subtotalPrice || 0,
            shipping: 0,
            total: res.totalPrice || 0,
          });

          const product = products.filter((item) => item.skuId === skuId);
          if (product && product[0]) {
            OrderAnalytics.addAdditionalClick(product[0].type);
          }
        },
      );
    }
  };

  return (
    <Layout location={location.pathname} isSideMenu={false} className={styles.layout}>
      <Preloader isActive={loading || orderPlacing} />
      <Header
        className={styles.logoHeader}
        printLocation={false}
        goBack={() => {
          isSuccess ? navigate('/') : navigateToDeliveries();
        }}
      />
      <BackgroundImage Tag="div" fadeIn={false} className={styles.background} fluid={background} />

      {isSuccess && <CartHeader title="IT’S ORDERED!" />}
      {isSuccess && (
        <section className={styles.cartPage}>
          <Success email={profile?.email || ''} />

          {isProduction ? (
            <div
              dangerouslySetInnerHTML={{
                __html: `
                  <img alt="Traffic Roots Conversion Pixel" src="//service.trafficroots.com/conversion/289fac544c?click_id=your_click_id&amount=conversion_amount" style="display:none;">
                `,
              }}
            />
          ) : null}
        </section>
      )}

      {showTimerCart && <CartHeader title="Thank you for your order" />}
      {showTimerCart && (
        <section className={styles.cartPage}>
          <TimerCart
            activeType={state.activeType}
            products={products}
            total={total}
            minPrice={minOrderPrice / 100}
            orderNumber={orderNumber}
            onEnd={() => {
              setShowTimerCart(false);
              setCrossSale(null);
              setIsSuccess(true);
            }}
            email={profile?.email || ''}
            onOrderUpdate={onOrderUpdate}
            crossSaleProducts={crossSaleProducts}
            time={time}
          />
        </section>
      )}

      {!showTimerCart && !isSuccess && <ClickInfo />}
      {!showTimerCart && !isSuccess && (
        <section className={styles.cartPage}>
          <Cart
            activeType={state.activeType}
            products={products}
            onChangeQuantity={onChangeQuantity}
            quantity={quantity}
            total={total}
            minPrice={minOrderPrice / 100}
            isFirstPurchase={isFirstPurchase}
            calculating={calculating}
          />
          <VizSensor
            partialVisibility
            scrollCheck={true}
            scrollThrottle={10}
            onChange={(isVisible) => {
              setIsVisible(isVisible);
            }}
          >
            <div>
              <DeliveryAddress
                data={state.deliveryData}
                onSubmit={onSubmit}
                getPlaceAutocomplete={getPlaceAutocomplete}
                getPlaceDetails={getPlaceDetails}
                onChangePlaceId={(placeId: string) => {
                  setPlaceId(placeId);
                  setIsDefaultAddress(false);
                }}
                place={isDefaultAddress ? null : place}
                isActiveSubmit={!calculating && (+total.originSubtotal || 0) >= minOrderPrice}
                scheduled={isDefaultAddress ? [] : scheduled}
                navigateToDeliveries={navigateToDeliveries}
                isVisibleForm={isVisible}
                minPrice={minOrderPrice / 100}
              />
              <Information />
            </div>
          </VizSensor>
        </section>
      )}
    </Layout>
  );
};

export default CartView;
