import React, { useState } from 'react';

import styles from './Information.module.scss';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';

const Information: React.FC = () => {
  const [hideTermsOfService, setHideTermsOfService] = useState(true);
  const [hidePrivacyPolicy, setHidePrivacyPolicy] = useState(true);

  return (
    <section className={styles.screenBox}>
      <div className={styles.popupLink} onClick={() => setHideTermsOfService(false)}>
        {' '}
        Terms Of Service
      </div>
      <div className={styles.popupLink} onClick={() => setHidePrivacyPolicy(false)}>
        {' '}
        Privacy Policy
      </div>

      <TermsOfService
        hide={hideTermsOfService}
        hideTermsOfService={() => setHideTermsOfService(true)}
      />

      <PrivacyPolicy
        hide={hidePrivacyPolicy}
        hidePrivacyPolicy={() => setHidePrivacyPolicy(true)}
      />
    </section>
  );
};

export default Information;
