import React, { useState, useEffect } from 'react';
import CartProduct from './CartProduct';
import CartFooter from './CartFooter';
import Promotions from '../promotions/Promotions';
import stylesModule from '../Cart.module.scss';

interface ICartProps {
  products: Cart.Product[];
  activeType: string;
  quantity: any;
  total: any;
  onChangeQuantity: (data: any) => void;
  minPrice: number;
  isFirstPurchase: boolean;
  calculating: boolean;
}

let timer: any;

const Cart: React.FC<ICartProps> = ({
  products,
  total,
  quantity,
  onChangeQuantity,
  minPrice,
  isFirstPurchase,
  calculating,
}) => {
  const print_products = products.slice(0, 4);
  const [productsQuantity, setProductsQuantity] = useState({});

  useEffect(() => {
    if (products.length) {
      if (!isEqualQuantity(productsQuantity)) {
        setProductsQuantity(quantity);
      }
    }
  }, [quantity]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      if (products.length) {
        if (!isEqualQuantity(productsQuantity)) {
          onChangeQuantity(productsQuantity);
        }
      }
    }, 500);
  }, [productsQuantity]);

  const onChangeDebounced = (data: any) => {
    setProductsQuantity({ ...productsQuantity, ...data });
  };

  const isEqualQuantity = (data: any) => {
    let is_equal = true;
    if (products.length) {
      for (let i = 0; i < products.length; i++) {
        if (products[i].type && data[products[i].type] !== quantity[products[i].type]) {
          is_equal = false;
        }
      }
    }
    return is_equal;
  };

  return (
    <div className={stylesModule.cart}>
      <div className={stylesModule.body}>
        <div className={stylesModule.cartContent}>
          <div className={stylesModule.cartGrid}>
            {print_products.map((item) =>
              item.skuId ? (
                <CartProduct
                  key={item.name}
                  product={item}
                  quantity={quantity[item.type] || 0}
                  onChange={(data: any) => onChangeDebounced(data)}
                />
              ) : null,
            )}
          </div>
          <hr className={stylesModule.lineSeparator} />

          {isFirstPurchase && <Promotions />}

          <p className={stylesModule.importantText}>*${minPrice} Minimum On All Orders.</p>
        </div>
      </div>
      <CartFooter calculating={calculating} total={total} />
    </div>
  );
};

export default Cart;
