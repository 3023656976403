import React from 'react';

import logo from 'images/Logo-Mark-White.svg';

import styles from './Promotions.module.scss';

const Promotions: React.FC = () => {
  return (
    <div className={styles.promotions}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo" />
      </div>
      <div className={styles.text}>
        <b>Get 50% OFF when you buy 2 or more CLICKs!</b>
      </div>
    </div>
  );
};

export default Promotions;
