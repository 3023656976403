import React from 'react';
import classnames from 'classnames';
import { navigate } from 'gatsby';

import Layout from 'components/layouts/shop/Layout';

import styles from './ShopAuthLayout.module.scss';

interface IShopAuthLayoutProps {
  isSocialSignUp: boolean;
}

const ShopAuthLayout: React.FC<IShopAuthLayoutProps> = ({ isSocialSignUp, children }) => (
  <Layout goBack={() => navigate('/shop/cart')}>
    <div className={classnames(styles.root, { 'okta-social-only': isSocialSignUp })}>
      {children}
    </div>
  </Layout>
);

export default ShopAuthLayout;
