import React from 'react';

import Heading from 'components/shared/heading/Heading';

import styles from './ClickInfo.module.scss';

const ClickInfo = () => (
  <div className={styles.clickInfo}>
    <div className={styles.clickInfoLine}>
      <hr />
      <span>Approximately</span>
      <hr />
    </div>
    <div className={styles.clickInfoData}>
      <div>
        <Heading
          color="#fffff"
          underline={false}
          shadowColor="#FF3800"
          className={styles.clickInfoHeading}
        >
          200
        </Heading>
        <div className={styles.text}>mg</div>
      </div>
      <div>
        <Heading
          color="#fffff"
          underline={false}
          shadowColor="#FF3800"
          className={styles.clickInfoHeading}
        >
          2.5
        </Heading>
        <div className={`${styles.text} ${styles.withDescription}`}>
          <span>mg</span>
          <span className={styles.smallText}>per click</span>
        </div>
      </div>
      <div>
        <Heading
          color="#fffff"
          underline={false}
          shadowColor="#FF3800"
          className={styles.clickInfoHeading}
        >
          80
        </Heading>
        <div className={styles.text}>CLICKS</div>
      </div>
    </div>
  </div>
);

export default ClickInfo;
