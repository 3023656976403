import React from 'react';

import { ClickTypes } from 'services/enums';

import CartFooter from '../cart/CartFooter';
import Promotions from '../promotions/Promotions';
import Timer from '../timer/Timer';

import { product_styles } from '../../styles';
import styles from './TimerCart.module.scss';
import stylesModule from '../Cart.module.scss';

interface ICartProps {
  products: Cart.Product[];
  activeType: string;
  email: string;
  total: any;
  minPrice: number;
  orderNumber: number;
  time: number;
  onEnd: () => void;
  onOrderUpdate: (skuId: number) => void;
  crossSaleProducts: number[];
}
const getStyle = (type: ClickTypes) => product_styles[type];

const TimerCart: React.FC<ICartProps> = ({
  products,
  total,
  minPrice,
  orderNumber,
  email,
  onEnd,
  onOrderUpdate,
  crossSaleProducts,
  time,
}) => {
  const print_products = products.slice(0, 4);
  return (
    <div className={stylesModule.cart}>
      <div className={stylesModule.body}>
        <div className={stylesModule.cartContent}>
          <div className={styles.text}>
            Your order number is {orderNumber}.<br />
            You will receive an order confirmation shortly at
            <br />
            <div className={styles.overflow}>{email}</div>
          </div>

          <div className={styles.importantText}>
            Get an additional CLICKs for just $20 each.
            <br /> Add to your cart in the next
          </div>
          <Timer stop={onEnd} time={time} />

          <div className={stylesModule.cartGrid}>
            {print_products.map(
              (product) =>
                product.skuId &&
                !crossSaleProducts.includes(product.skuId) && (
                  <div
                    key={product.name}
                    className={`${stylesModule.productItem} ${styles.productItem}`}
                    style={{ color: getStyle(product.type).color }}
                  >
                    <div className={stylesModule.productImage}>
                      <img src={getStyle(product.type).image} />
                    </div>

                    <div className={styles.addProduct} onClick={() => onOrderUpdate(product.skuId)}>
                      Add 1 <span>{product.type}</span>
                    </div>
                  </div>
                ),
            )}
          </div>
          <hr className={stylesModule.lineSeparator} />
          <p onClick={onEnd} className={`${stylesModule.importantText} ${styles.link}`}>
            No thanks, I prefer to pay full price
          </p>
        </div>
      </div>
      <CartFooter total={total} />
    </div>
  );
};

export default TimerCart;
