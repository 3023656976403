import React from 'react';
import { ClickTypes } from 'services/enums';
import InputQuantity from './InputQuantity';
import { product_styles } from '../../styles';
import stylesModule from '../Cart.module.scss';
import { applyPromotionToPrice } from './applyPromotionToPrice';

interface ICartProps {
  product: {
    [key: string]: any;
  };
  quantity: number;
  onChange: (p: any) => void;
}

const renderPrice = (price: number) => {
  return `${price / 100}.00`;
};

const CartProduct: React.FC<ICartProps> = ({ product, quantity, onChange }) => {
  let type: ClickTypes = product.type;
  let style = product_styles[type];

  product = applyPromotionToPrice(product);

  const trackSurfsideCartChange = (
    oldQuantity: number,
    newQuantity: number,
    product: any,
  ): void => {
    // product content
    // brandId: 188
    // brandName: "Click"
    // categoryId: 13
    // currency: "USD"
    // description: "1:5 THC: CBD + Boswellia +\nLemonGingerGrass Flavor\n\nCLICK’S RESTORE formulation is custom built for those who want to restore their body after a workout, trail run, spin class or even a crazy cross town commute. Using the highest grade of CBD and THC - we took the revitalizing citrus properties of all-natural lemon extract and combined it with the calming and soothing properties of Ginergrass to give your body that break it needs after a long day. We also included some Baswallia to help reduce painful joint-inflammation and aid in recovery! Bring it on leg day!\n\n• 200mg/80 clicks/2.5mg per click\n\n• Keychain hook\n\n• Patented ergonomic design\n\n• Lock/unlock function\n\nTHC 34mg    CBD 166mg\n\nNet Wt. 4.3mL, .145 fl. oz., 200mg of total cannabinoids\n\nUnit size: 5x5x1"
    // effects: null
    // imageId: 514
    // imageUrl: "https://d3rcyfva2h9yhp.cloudfront.net/4fa7450b-541e-4150-8c7b-0cbf1eced0cc"
    // name: "Click Spray - Restore"
    // newPrice: 2000
    // price: 2500
    // productId: 3040
    // promotions: [{…}]
    // quantity: 99981
    // skuId: 2866
    // type: "restore"

    if (oldQuantity === newQuantity) {
      return;
    }

    const action: string = oldQuantity > newQuantity ? 'remove' : 'add';

    (window as any).surf(
      'addProduct',
      product.skuId, // Product ID
      product.name, // Product name
      '', // Product list
      product.brandName, // Product brand
      product.categoryId, // Product category
      '', // Product variant
      product.price / 100, // Product price
      Math.abs(oldQuantity - newQuantity), // Product quantity
      '', // Product coupon
      0, // Product position
      product.currency, // Product currency
    );

    (window as any).surf(
      'setCommerceAction',
      action,
      // 'remove',
      // 'add',
      // 'purchase', // Purchase Action
      // 'refund', // Purchase Action
      // ''view'', // Purchase Action
    );
  };

  const changeQuantity = (value: number) => {
    try {
      trackSurfsideCartChange(quantity, +value, product);
    } catch (e) {}

    onChange({ [product.type]: +value });
  };

  return (
    <div className={stylesModule.productItem} key={product.name} style={{ color: style.color }}>
      <div className={stylesModule.productName}>{product.type}</div>
      <div className={stylesModule.productDescription}>{style.description}</div>
      <div className={stylesModule.productImage}>
        <img src={style.image} />
      </div>

      <div className={stylesModule.productPrice}>
        {product.price > product.newPrice && (
          <p className={stylesModule.productPriceDiscount}>${renderPrice(product.price)}</p>
        )}
        <div>
          ${renderPrice(product.price > product.newPrice ? product.newPrice : product.price)}
        </div>
      </div>
      <div>
        <InputQuantity
          onChange={changeQuantity}
          maxQuantity={product.quantity}
          quantity={quantity}
          name={product.type}
        />
      </div>

      <div className={stylesModule.productFormulation}>{style.formulation}</div>
      <div
        className={stylesModule.productFormulation}
        dangerouslySetInnerHTML={{ __html: style.descriptionInfo }}
      />
    </div>
  );
};

export default CartProduct;
