export enum DeliveryStages {
  delivery = 'delivery',
  nearestStores = 'nearestStores',
  login = 'login',
}

export enum DeliveryDialogDeliverTypes {
  deliverHere = 'deliverHere',
  deliverTo = 'deliverTo',
}

export enum DeliveryServiceTypes {
  deliverByClick = 'deliverByClick',
  deliveryService = 'deliveryService',
  byAtStore = 'byAtStore',
}
