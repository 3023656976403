import { ClickTypes } from 'services/enums';

import shadowGo from 'images/triangle-shadow-go.png';
import shadowRestore from 'images/triangle-shadow-restore.png';
import shadowChill from 'images/triangle-shadow-chill.png';
import shadowDream from 'images/triangle-shadow-dream.png';

export const colors: { [key: string]: string } = {
  [ClickTypes.go]: '#FF3800',
  [ClickTypes.restore]: '#6ADF8B',
  [ClickTypes.chill]: '#5E91FF',
  [ClickTypes.dream]: '#000059',
};

export const images: { [key: string]: string } = {
  [ClickTypes.go]: shadowGo,
  [ClickTypes.restore]: shadowRestore,
  [ClickTypes.chill]: shadowChill,
  [ClickTypes.dream]: shadowDream,
};

export const product_styles = {
  [ClickTypes.go]: {
    image: '/assets/shop/cart/go.png',
    color: '#FF3800',
    formulation: 'THC 1:0 CBD + Green Tea + MoveMint Flavor',
    description: 'The go-to boost from your cannabis',
    descriptionInfo: `Great for:<br />
    • Boost of energy + focus<br />
    • Getting your day started<br />
    • Physical activity`,
  },
  [ClickTypes.restore]: {
    image: '/assets/shop/cart/restore.png',
    color: '#6ADF8B',
    formulation: 'THC 1:5 CBD + Boswellia + Lemon Ginger Bliss Flavor',
    description: 'Restore Yourself',
    descriptionInfo: `Great for:<br />
    • Focused relaxation<br />
    • Treating aches + pains<br />
    • Post-workout recovery`,
  },
  [ClickTypes.chill]: {
    image: '/assets/shop/cart/chill.png',
    color: '#5E91FF',
    formulation: 'THC 2:1 CBD + L-Theanine + TropiCali Flavor',
    description: 'Chill out, you’ve earned it',
    descriptionInfo: `Great for:<br />
    • Chilling out<br />
    • De-stressing after your day<br />
    • Managing anxiety`,
  },
  [ClickTypes.dream]: {
    image: '/assets/shop/cart/dream.png',
    color: '#000059',
    formulation: 'THC 1:2 CBD + Melatonin + Midnight Mint Flavor',
    description: 'Get your sweet dreams right here',
    descriptionInfo: `Great for:<br />
    • Falling + staying asleep<br />
    • Nighttime use<br />
    • Sweet dreams`,
  },
};

export const product_names = [
  ClickTypes.go,
  ClickTypes.restore,
  ClickTypes.chill,
  ClickTypes.dream,
];

export const getProductsStyle = () => {
  return product_names.map((item) => ({
    id: item,
    name: item,
    color: colors[item],
    image: images[item],
    transform: screenStyle.get(item).transform,
    boxShadowDetails: screenStyle.get(item).boxShadowDetails,
  }));
};

export const productsImages: Cart.Images = {
  [ClickTypes.go]: {
    small: '/assets/shop/cart/go_small.jpg',
    big: '/assets/shop/cart/go.jpg',
  },
  [ClickTypes.restore]: {
    small: '/assets/shop/cart/restore_small.jpg',
    big: '/assets/shop/cart/restore.jpg',
  },
  [ClickTypes.chill]: {
    small: '/assets/shop/cart/chill_small.jpg',
    big: '/assets/shop/cart/chill.jpg',
  },
  [ClickTypes.dream]: {
    small: '/assets/shop/cart/dream_small.jpg',
    big: '/assets/shop/cart/dream.jpg',
  },
};
