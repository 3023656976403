import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Form, Formik, Field, FieldProps } from 'formik';
import AsyncSelect from 'react-select/async';
import moment from 'moment';

import useAuth from 'hooks/useAuth';
import * as ValidationAlgorithms from 'services/helpers/ValidationAlgorithms';
import DeliveryDialog from 'components/shared/delivery-dialog/Delivery';
import OrderAnalytics from 'core/framework/analytic/OrderFlow';

import styles from './DeliveryAddressForm.module.scss';
import selectCustomStyle from './SelectCustomStyle';

interface IDeliveryAddressFormProps {
  onSubmit: (data: Cart.AddressCreate, timeslotInfo: any) => void;
  getPlaceAutocomplete(data: string, callback: (p: any) => void): void;
  getPlaceDetails(data: string, callback: (p: any) => void): void;
  data: any;
  defaultPlace: any;
  setShow: any;
  isActiveSubmit: boolean;
  onChangePlaceId: (placeId: string) => void;
  scheduled: any;
  navigateToDeliveries: (data: any) => void;
}

const getField = (list: any, field: string) => {
  let res = list.filter((item: any) => item.types && item.types[0] === field);
  let value = res && res[0] && res[0].longName;
  return value || 0;
};

const DeliveryAddressForm = ({
  onSubmit,
  getPlaceAutocomplete,
  getPlaceDetails,
  defaultPlace,
  setShow,
  isActiveSubmit,
  onChangePlaceId,
  scheduled,
  navigateToDeliveries,
}: IDeliveryAddressFormProps) => {
  const [apt, setApt] = useState('');
  const [date, setDate] = useState(undefined);
  const [time, setTime] = useState(undefined);
  const [printScheduled, setPrintScheduled] = useState([]);
  const [printScheduledTime, setPrintScheduledTime] = useState([]);
  const [show, setShowDeliveryDialog] = useState(false);
  const [address, setAddress] = useState(defaultPlace);

  const {
    state: { isAuthenticated },
  } = useAuth();

  useEffect(() => {
    if (defaultPlace) {
      setAddress(defaultPlace);
    }
  }, [defaultPlace]);

  useEffect(() => {
    if (scheduled.length) {
      let _Scheduled = scheduled.map((item: any) => item.entity);
      setPrintScheduled(_Scheduled);
      setDate(_Scheduled[0]);

      setPrintScheduledTime(_Scheduled[0].intervals);
      setTime(_Scheduled[0].intervals[0]);
    }
  }, [scheduled]);

  const loadOptionsDate = (inputValue: string, callback: (p: any) => void) => {
    callback(printScheduled);
  };

  const loadOptionsTime = (inputValue: string, callback: (p: any) => void) => {
    callback(printScheduledTime);
  };

  const onChangeAddress = (data: any) => {
    onChangePlaceId(data.placeId);
    setAddress(data);
    setShowDeliveryDialog(false);
  };

  const goToLocator = (data: Entities.PlaceDetails) => {};

  const onSubmitEvent = (data: any) => {
    onSubmit(
      { placeId: address.placeId, flat: data.flat },
      { date: data.date.date, start: data.time.start, end: data.time.end },
    );
  };

  const printTime = (time: string) => moment(time, 'HH:mm:ss').format('h:mm a');

  const printDate = (date: string) => moment(date).format('dddd, MMMM D');

  return (
    <div className={styles.deliveryAddressForm}>
      {
        /*date && time ? (*/
        <Formik
          initialValues={{
            flat: '',
            date: date,
            time: time,
            address: address?.address || '',
          }}
          validateOnChange
          onSubmit={(data) => onSubmitEvent(data)}
        >
          {({ isValid, values, setFieldValue }) => (
            <Form className={styles.form}>
              <div className={styles.row}>
                <div className={styles.field}>
                  <div className={`${styles.input} ${styles.openModal}`}>
                    <div className={styles.text}>{address?.address || 'Select address'}</div>
                    <div className={styles.link} onClick={() => setShowDeliveryDialog(true)}>
                      Change Address
                    </div>
                  </div>
                  <Field
                    name="address"
                    validate={() => (address?.address ? '' : 'Address is required')}
                  >
                    {({ field, meta }: FieldProps) => (
                      <div className={classnames(styles.field, styles.apt)}>
                        {meta.error && meta.touched && (
                          <span className={styles.error}>{meta.error}</span>
                        )}
                        <span />
                      </div>
                    )}
                  </Field>
                </div>
                <Field name="flat">
                  {({ field, meta }: FieldProps) => (
                    <div className={classnames(styles.field, styles.apt)}>
                      <input
                        className={styles.input}
                        {...field}
                        onBlur={() => field.value && OrderAnalytics.enterApt()}
                        placeholder="APT#"
                      />
                      {meta.error && meta.touched && (
                        <span className={styles.error}>{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>
              </div>
              <div className={styles.row}>
                <Field name="date" validate={ValidationAlgorithms.validateString('date')}>
                  {({ field, meta }: FieldProps) => (
                    <div className={styles.field}>
                      <AsyncSelect
                        value={values.date}
                        styles={selectCustomStyle}
                        getOptionLabel={(option: any) => printDate(option.date)}
                        cacheOptions
                        loadOptions={loadOptionsDate}
                        defaultOptions={printScheduled}
                        placeholder={'DELIVERY DATE'}
                        isSearchable={false}
                        isClearable={false}
                        onChange={(option: any) => {
                          setFieldValue(field.name, option);
                          setPrintScheduledTime(option.intervals);
                          OrderAnalytics.selectDeliveryDate();
                        }}
                      />
                      {meta.error && meta.touched && (
                        <span className={styles.error}>{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>
                <Field name="time" validate={ValidationAlgorithms.validateString('time')}>
                  {({ field, meta }: FieldProps) => (
                    <div className={styles.field}>
                      <AsyncSelect
                        value={values.time}
                        styles={selectCustomStyle}
                        getOptionLabel={(option: any) =>
                          `${printTime(option.start)} - ${printTime(option.end)}`
                        }
                        cacheOptions
                        loadOptions={loadOptionsTime}
                        defaultOptions={printScheduledTime}
                        placeholder={'DELIVERY TIME'}
                        isSearchable={false}
                        isClearable={false}
                        onChange={(option: any) => {
                          setFieldValue(field.name, option);
                          OrderAnalytics.selectDeliveryTime();
                        }}
                      />
                      {meta.error && meta.touched && (
                        <span className={styles.error}>{meta.error}</span>
                      )}
                    </div>
                  )}
                </Field>
              </div>

              <button
                type="submit"
                className={classnames(styles.submitButton, {
                  [styles.disabled]: !isActiveSubmit || !isValid,
                })}
                disabled={!isActiveSubmit || !isValid}
              >
                PLACE ORDER
              </button>
              <DeliveryDialog
                show={show}
                onSubmit={(data: any) => {
                  onChangeAddress(data);
                  setFieldValue('address', data.placeId);
                }}
                onHide={() => setShowDeliveryDialog(false)}
                goToLocator={(data) => {
                  navigateToDeliveries(data);
                  setShowDeliveryDialog(false);
                }}
                successEvent={() => OrderAnalytics.changeAddressOrder()}
                needCheckDelivery={true}
                sorryTitle="Sorry. CLICK is currently not available in your area.<br /> Please sign up for alerts so you will be the first to know <br />when we are in your area.<br />"
              />
            </Form>
          )}
        </Formik>
        /*) : (
          <div className={styles.form}>
            <div className={styles.row}>
              <div className={styles.field}>
                <div className={`${styles.input}`}>street address</div>
              </div>
              <div className={styles.field}>
                <div className={`${styles.input}`}>APT#</div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <div className={`${styles.input}`}>DELIVERY DATE</div>
              </div>
              <div className={styles.field}>
                <div className={`${styles.input}`}>DELIVERY time</div>
              </div>
            </div>
            <button
              type="submit"
              className={classnames(styles.submitButton, {
                [styles.disabled]: true,
              })}
              disabled={true}
            >
              PLACE ORDER
            </button>
          </div>
        )
            */
      }
    </div>
  );
};

export default DeliveryAddressForm;
