import _ from 'lodash';
import { Store } from 'core/framework';

import { DeliveryStages } from '../enums';

const initialState: Delivery.StoreState = {
  deliveries: [],
  destinations: [],
  activeDestinationType: null,
  isZipCodeVisible: false,
  zipCodeValue: '',
  placeDetails: {} as Entities.PlaceDetailsRaw,
  currentStage: DeliveryStages.delivery,
  isLoading: false,
};

class DeliveryStore extends Store<Delivery.StoreState> {
  constructor() {
    super(initialState);
  }

  setDeliveries(state: Delivery.StoreState, deliveries: Delivery.StoreActionPayload) {
    return _.merge({}, state, { deliveries });
  }

  setDeliveryDestinations(state: Delivery.StoreState, payload: Delivery.StoreActionPayload) {
    return _.merge({}, state, {
      destinations: payload.destinations,
      activeDestinationType: payload.activeDestinationType,
      isZipCodeVisible: payload.isZipCodeVisible,
    });
  }

  setDialogZipCodeValue(state: Delivery.StoreState, zipCodeValue: Delivery.StoreActionPayload) {
    return _.merge({}, state, { zipCodeValue });
  }

  setPlaceDetails(state: Delivery.StoreState, placeDetails: Entities.PlaceDetails) {
    return _.merge({}, state, { placeDetails });
  }

  changeCurrentStage(state: Delivery.StoreState, currentStage: Delivery.StoreActionPayload) {
    return _.merge({}, state, { currentStage });
  }

  setLoading(state: Delivery.StoreState, isLoading: boolean) {
    return _.merge({}, state, { isLoading });
  }
}

export default DeliveryStore;
