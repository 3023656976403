import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import OrderAnalytics from 'core/framework/analytic/OrderFlow';
import { isProduction } from 'services/config';

import Layout from 'components/layouts/shop/Layout';
import DeliveryDialog from 'components/shared/delivery-dialog/Delivery';
import DeliveryList from './components/deliveryList/DeliveryList';
import NearestStores from './components/nearestStores/NearestStores';

import { DeliveryStages } from '../enums';

import styles from './DeliveryView.module.scss';

const DeliveryView: React.FC<Delivery.View> = ({ usePresenter }) => {
  const { state, ...actions } = usePresenter();

  const { placeDetails, currentStage, isLoading, market } = state;

  const {
    navigateToCart,
    navigateToNearestStores,
    loadRetailersNear,
    checkDelivery,
    setPlaceDetails,
    getDetailsByPlaceId,
  } = actions;

  const [show, setShow] = useState(false);

  useEffect(() => {
    onCheckNavigate();
    OrderAnalytics.deliveryPage();
  }, []);

  const onCheckNavigate = () => {
    if (Object.keys(Object.assign(placeDetails.entity)).length === 0) {
      if (market.defaultPlaceId) {
        getDetailsByPlaceId(market.defaultPlaceId);
      } else {
        setShow(true);
      }
    } else {
      checkDelivery();
    }
  };

  const onSubmit = (data: any) => {
    setPlaceDetails(data);
    setShow(false);
  };

  const goToLocator = (data: any) => {
    setPlaceDetails(data);
    setShow(false);
  };

  return (
    <Layout
      loading={isLoading}
      goBack={() => navigate('/')}
      openLocationDialog={() => {
        setShow(true);
        OrderAnalytics.changAddress();
      }}
    >
      {currentStage === DeliveryStages.delivery ? (
        <DeliveryList
          className={styles.deliveryList}
          buyNow={navigateToCart}
          storeList={navigateToNearestStores}
          loadRetailersNear={loadRetailersNear}
          placeDetails={placeDetails}
        />
      ) : (
        <NearestStores placeDetails={placeDetails} />
      )}
      <DeliveryDialog
        show={show}
        goToLocator={goToLocator}
        onSubmit={onSubmit}
        onHide={() => setShow(false)}
        successEvent={() => OrderAnalytics.changeAddressDelivery()}
      />

      {isProduction ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `
          <img alt="Traffic Roots Conversion Pixel" src="//service.trafficroots.com/conversion/08378e92ac?click_id=your_click_id&amount=conversion_amount" style="display:none;">
        `,
          }}
        />
      ) : null}
    </Layout>
  );
};

export default DeliveryView;
