import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';

import DeliveryAddressForm from './form/DeliveryAddressForm';
import Modal from './modal/Modal';
import Dialog, { DeliveryDialogTypes } from 'components/shared/delivery-dialog/DeliveryDialog';

import styles from './DeliveryAddress.module.scss';
import Cart from '../cart/Cart';

interface IDeliveryAddressProps {
  data: Cart.DeliveryData;
  onSubmit: (data: any, timeslotInfo: any) => void;
  getPlaceAutocomplete(data: string, callback: (p: DeliveryAddress.ResponseList) => void): void;
  getPlaceDetails(data: string, callback: (p: DeliveryAddress.ResponseDetails) => void): void;
  place: any;
  isActiveSubmit: boolean;
  isVisibleForm: boolean;
  onChangePlaceId: (placeId: string) => void;
  scheduled: any;
  navigateToDeliveries: (data: any) => void;
  minPrice: number;
}

const DeliveryAddress = ({
  onSubmit,
  getPlaceAutocomplete,
  getPlaceDetails,
  place,
  data,
  isActiveSubmit,
  onChangePlaceId,
  scheduled,
  navigateToDeliveries,
  isVisibleForm,
  minPrice,
}: IDeliveryAddressProps) => {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(isVisibleForm);

  useEffect(() => {
    setIsVisible(isVisibleForm);
  }, [isVisibleForm]);

  const buyForm = useRef<React.RefObject<any>>(null);

  const scrollTo = (element: HTMLElement | null) =>
    element?.current.scrollIntoView({ behavior: 'smooth' });

  return (
    <section className={styles.deliveryAddress}>
      <div className={styles.deliverTitle} ref={buyForm}>
        DELIVER TO
      </div>

      <button
        type="submit"
        className={classnames(styles.submitButton)}
        onClick={() => {
          isActiveSubmit ? scrollTo(buyForm) : setShowModal(true);
        }}
        style={isVisible ? { display: 'none' } : { position: 'fixed', bottom: '0' }}
      >
        PLACE ORDER
      </button>

      {showModal && (
        <Modal show={showModal} minPrice={minPrice} onHide={() => setShowModal(false)} />
      )}
      <DeliveryAddressForm
        data={data}
        onSubmit={onSubmit}
        setShow={() => setShow(true)}
        getPlaceAutocomplete={getPlaceAutocomplete}
        getPlaceDetails={getPlaceDetails}
        defaultPlace={place}
        isActiveSubmit={isActiveSubmit}
        onChangePlaceId={onChangePlaceId}
        scheduled={scheduled}
        navigateToDeliveries={navigateToDeliveries}
      />

      {show && (
        <Dialog
          className={styles.deliveryDialog}
          defaultValue={''}
          submit={() => setShow(false)}
          close={() => setShow(false)}
          title="Unfortunately you are not in our delivery area.<br />
          Fear not! There may be a dispensary near you."
          type={DeliveryDialogTypes.email}
          changeAddress={() => setShow(false)}
        />
      )}
    </section>
  );
};

export default DeliveryAddress;
