import { navigate } from '@reach/router';
import { Presenter } from 'core/framework';

class CartPresenter extends Presenter<Cart.Model, Cart.StoreState, any> implements Cart.Presenter {
  public usePresenter(): Cart.PresenterHook {
    const { store } = this;
    const { state } = store.useReducer();

    return {
      state: {
        ...state,
        market: this.config.market,
        background: this.config.background,
      },
      getQuantity: this.getQuantity,
      navigateToDeliveries: this.navigateToDeliveries,
      changeQuantity: this.changeQuantity,
      calcOrder: this.calcOrder,
      getProducts: this.getProducts,
      initPlace: this.initPlace,
      setDeliveryAddress: this.setDeliveryAddress,
      addressCreate: this.addressCreate,
      orderCreate: this.orderCreate,
      getProductIds: this.getProductIds,
      getPlaceAutocomplete: this.getPlaceAutocomplete,
      getPlaceDetails: this.getPlaceDetails,
      getDefaultPlace: this.getDefaultPlace,
      getCart: this.getCart,
      orderCancel: this.orderCancel,
      orderCheckout: this.orderCheckout,
      getAuthorization: this.getAuthorization,
      orderUpdate: this.orderUpdate,
      findTimeslots: this.findTimeslots,
      checkDiscount: this.checkDiscount,
      calcOrderCross: this.calcOrderCross,
      getCrossSale: this.getCrossSale,
      setCrossSale: this.setCrossSale,
    };
  }

  private navigateToDeliveries(data?: any) {
    if (data) {
      this.model.updateDelivery(data).then(() =>
        setTimeout(() => {
          navigate('/shop/delivery');
        }, 100),
      );
    } else {
      navigate('/shop/delivery');
    }
  }

  private changeQuantity(data: Cart.Quantity) {
    this.model.changeQuantity(data);
  }

  private calcOrder(callback: (p: Cart.CalculateOrder) => void, token?: string) {
    this.model.calcOrder(token).then(callback).catch(callback);
  }

  private calcOrderCross(data: any, callback: (p: Cart.CalculateOrder) => void) {
    this.model.calcOrderCross(data).then(callback).catch(callback);
  }

  private initPlace() {
    return this.model.initPlace();
  }

  private getProducts(callback: (p: Cart.Product[]) => void) {
    this.model.getProducts().then(callback).catch(callback);
  }

  private getQuantity() {
    return this.model.getQuantity();
  }
  private setDeliveryAddress(data: any) {
    this.dispatch.setDeliveryAddress(data);
  }

  private getPlaceAutocomplete(data: string, callback: (p: any) => void) {
    this.model.getPlaceAutocomplete(data).then(callback).catch(callback);
  }

  private getPlaceDetails(data: string, callback: (p: any) => void) {
    this.model.getPlaceDetails(data).then(callback).catch(callback);
  }

  private addressCreate(data: any, callback: (p: string) => void) {
    this.model.addressCreate(data).then(callback).catch(callback);
  }

  private orderCreate(
    addressId: number,
    data: Cart.Order[],
    callback: (p: Cart.Product[]) => void,
  ) {
    this.model.orderCreate(addressId, data).then(callback).catch(callback);
  }
  private getCart(callback: (p: any) => void) {
    this.model.getCart().then(callback).catch(callback);
  }

  private orderCancel(orderId: number, callback: (p: any) => void) {
    this.model.orderCancel(orderId).then(callback).catch(callback);
  }

  private orderUpdate(
    orderId: number,
    addressId: number,
    data: Cart.Order[],
    vauchers: { code: string }[],
    callback: (p: any) => void,
  ) {
    this.model.orderUpdate(orderId, addressId, data, vauchers).then(callback).catch(callback);
  }

  private orderCheckout(orderId: number, timeslotInfo: any, callback: (p: any) => void) {
    this.model.orderCheckout(orderId, timeslotInfo).then(callback).catch(callback);
  }

  private findTimeslots(placeId: string, depotId: number, callback: (p: any) => void) {
    this.model
      .findTimeslots(placeId, depotId)
      .then(callback)
      .catch((error) => {});
  }

  private checkDiscount(placeId: string) {
    return this.model.checkDiscount(placeId);
  }

  private getProductIds() {
    return this.model.getProductIds();
  }
  private getDefaultPlace() {
    return this.model.getDefaultPlace();
  }
  private getCrossSale(callback: (p: any) => void) {
    return this.model.getCrossSale().then(callback);
  }
  private setCrossSale(data: any) {
    return this.model.setCrossSale(data);
  }
  private getAuthorization(accessToken: string, callback: (p: any) => void) {
    return this.model.getAuthorization(accessToken).then(callback);
  }
}

export default CartPresenter;
