import React, { useState, useEffect } from 'react';

import { DeliveryServiceTypes } from 'modules/shop/delivery/enums';
import OrderAnalytics from 'core/framework/analytic/OrderFlow';

import DeliveryTypeItem from 'components/deliveryTypeItem/DeliveryTypeItem';
import Dispensary from 'components/shared/dispensaries-map/Dispensary';

import styles from './DeliveryList.module.scss';

interface IDeliveryListProps {
  className?: string;
  deliveries?: Delivery.DeliveryItem[];
  buyNow(): void;
  storeList(): void;
  loadRetailersNear(callback: (p: any) => void): void;
  placeDetails: any;
}

const DeliveryList: React.FC<IDeliveryListProps> = (props) => {
  const { className, deliveries, buyNow, loadRetailersNear, placeDetails } = props;
  const [dispensariesList, setDispensariesList] = useState([]);
  const [placeId, setPlaceId] = useState(placeDetails.entity?.placeId);

  useEffect(() => {
    loadRetailersNear((data) => {
      setDispensariesList(data);
    });
  }, []);

  useEffect(() => {
    if (placeDetails.entity?.placeId !== placeId) {
      setPlaceId(placeDetails.entity?.placeId);
      loadRetailersNear((data) => {
        setDispensariesList(data);
      });
    }
  }, [placeDetails]);

  return (
    <div className={className}>
      <DeliveryTypeItem
        id={DeliveryServiceTypes.deliverByClick}
        description=""
        select={() => {
          buyNow();
          OrderAnalytics.orderNow();
        }}
        withButton
      />
      <div className={styles.dispensariesRoll}>
        <div className={styles.icon}>
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="84" fill="#5E91FF">
            <path
              fillRule="evenodd"
              d="M59.4 29.7c0 5.6-1.5 10.9-4.3 15.4h.2L29.7 84 4 45h.2a29.7 29.7 0 1155.1-15.4zM29.7 18.4a11.3 11.3 0 100 22.6 11.3 11.3 0 000-22.6z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className={styles.title}>CLICK retailers near you</div>
        {dispensariesList.length ? (
          <div className={styles.list}>
            {dispensariesList.map((dispensary: any) => (
              <Dispensary
                isActive
                variant={Dispensary.variants.default}
                key={dispensary.id}
                dispensary={dispensary}
                className={styles.dispensariesRollItem}
                onRedirect={(name) => OrderAnalytics.redirectToRetailer(name)}
              />
            ))}
          </div>
        ) : (
          <p className={styles.noResults}>No results!</p>
        )}
      </div>
    </div>
  );
};

export default DeliveryList;
