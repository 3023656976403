import { isBrowser } from 'browser-or-node';
import {
  OrderService,
  MenuService,
  AddressService,
  MeService,
  PlaceService,
  OrderSkuCreateInputEntity,
  PaymentMethod,
  DeliveryType,
  CalculatedOrderEntity,
} from '@legalize/sdk';
import { v4 as uuidv4 } from 'uuid';
import { request, gql } from 'graphql-request';
import * as clicApi from 'services';
import StorageService from 'core/services/storageService/StorageService';
import { ClickTypes } from 'services/enums';
import PlaceDetailsEntity from 'core/entities/placeDetails/PlaceDetails';
import { generateUUID } from 'services/helpers';
import { marketId, brandIds, categoryClickIds, legalizeApiUrl } from 'services/config';

const product_names = [ClickTypes.go, ClickTypes.restore, ClickTypes.chill, ClickTypes.dream];

const islocalStorage = (isBrowser && window.localStorage) || undefined;
const isSessionStorage = (isBrowser && window.sessionStorage) || undefined;

class CartModel implements Cart.Model {
  private productIdsStorage: Cart.ProductIds = {};
  private deliveryStorage: any = {};

  private orderService: OrderService;
  private menuService: any;
  private placeService: PlaceService;
  private addressService: any;
  private meService: any;
  private crossSaleService: any;

  private productsQuantity: Cart.Quantity;
  private productIds: any = {};

  private placeId = '';
  private sessionId: string;
  private address: string;
  private authorization = '';
  private gingerAnonymousToken = '';

  constructor() {
    this.productsQuantity = new StorageService(islocalStorage, 'productsQuantity');
    this.productIdsStorage = new StorageService(islocalStorage, 'productIds');
    this.deliveryStorage = new StorageService(isSessionStorage, 'shop/delivery');
    this.crossSaleService = new StorageService(isSessionStorage, 'crossSale');

    this.orderService = new OrderService(legalizeApiUrl, '');
    this.menuService = new MenuService(legalizeApiUrl);

    this.placeService = new PlaceService(legalizeApiUrl);

    const details = (this.deliveryStorage.read() || {}).placeDetails;
    const placeDetails = new PlaceDetailsEntity(details);

    this.placeId = placeDetails.getPlaceId();
    this.address = placeDetails.getAddress();

    this.sessionId = generateUUID();
    this.getGingerAnonymousToken();
  }

  async getGingerAnonymousToken(): Promise<string> {
    if (!this.gingerAnonymousToken) {
      const query = gql`
          mutation {
              marketAnonymousUserAuth(input: { marketId: ${marketId}, anonymousId: "${uuidv4()}" }) {
                  token
              }
          }
      `;

      await request(legalizeApiUrl, query).then((data) => {
        this.gingerAnonymousToken = data?.marketAnonymousUserAuth?.token;
      });
    }

    return this.gingerAnonymousToken;
  }

  checkDiscount(placeId: string): Promise<CalculatedOrderEntity> {
    return this.orderService.calculate(marketId, placeId, [
      new OrderSkuCreateInputEntity({ skuId: this.productIds.go, quantity: 3 }),
    ]);
  }

  calcOrder(token?: string) {
    const quantity = this.getQuantity();
    const req: Cart.Order[] = [];
    for (const key in quantity) {
      const id = this.productIds && this.productIds[key];
      if (id && quantity[key]) {
        req.push({
          skuId: id,
          quantity: quantity[key],
        });
      }
    }
    return new Promise(async (resolve, reject) => {
      let gingerToken: string;
      if (token) {
        gingerToken = await clicApi
          .getLegalizeToken(token as string)
          .then((response) => response.json())
          .then((response) => response.token);
      } else {
        gingerToken = await this.getGingerAnonymousToken();
      }
      this.orderService.setAuthorization(gingerToken);

      this.orderService
        .calculate(marketId, this.placeId, req)
        .then((resultData: any) => {
          resolve(resultData?.entity || []);
        })
        .catch(reject);
    });
  }

  calcOrderCross(skus: any) {
    return new Promise((resolve, reject) =>
      this.orderService
        .calculate(marketId, this.placeId, skus)
        .then((resultData: any) => {
          resolve(resultData?.entity || []);
        })
        .catch(reject),
    );
  }

  getProducts() {
    return new Promise((resolve, reject) =>
      this.menuService
        .getGroupsByBrandAndCategoryIds(marketId, this.placeId, brandIds, categoryClickIds)
        .then((resultData: any) => {
          const results: any = [];
          let all = {};
          const products = [...(resultData[0]?.entity?.skus || [])];

          products.forEach((product) => {
            const product_name = product_names.filter(
              (item) => product.name && product.name.toLocaleLowerCase().indexOf(item) > -1,
            );
            if (product_name[0]) {
              const type = product_name[0];
              this.productIds[type] = product.skuId;
              results.push({
                ...product,
                type: type,
              });
            } else {
              this.productIds['all'] = product.skuId;
              all = { ...product, type: 'all' };
            }
          });

          this.productIdsStorage.write(this.productIds);
          resolve([...results, all]);
        })
        .catch(reject),
    );
  }

  getQuantity = () => {
    const quantity = this.productsQuantity.read(this.productIds);
    return quantity || {};
  };

  changeQuantity = (data: Cart.Quantity) => {
    this.productsQuantity.write(data);
  };

  initPlace = () => {
    const details = (this.deliveryStorage.read() || {}).placeDetails;
    const placeDetails = new PlaceDetailsEntity(details);
    this.placeId = placeDetails.getPlaceId();
    return this.placeId;
  };

  getAuthorization = (accessToken: string) => {
    return new Promise((resolve) =>
      clicApi
        .getLegalizeToken(accessToken)
        .then((response) => response.json())
        .then((resultData) => {
          const authorization = resultData.token || '';
          this.authorization = authorization;
          this.meService = new MeService(legalizeApiUrl, authorization);
          this.orderService = new OrderService(legalizeApiUrl, authorization);
          this.addressService = new AddressService(legalizeApiUrl, authorization);
          resolve();
        })
        .catch(() => resolve([])),
    );
  };

  addressCreate(data: Cart.AddressCreate) {
    return this.addressService.create(data.placeId, data.flat, '').then((resultData: any) => {
      return Promise.resolve(resultData?.entity || null);
    });
  }

  findTimeslots(placeId: string, depotId: number) {
    return this.orderService.findTimeslots(placeId, depotId).then((resultData: any) => {
      return Promise.resolve(resultData);
    });
  }

  orderCreate(addressId: number, data: Cart.Order[]) {
    return new Promise((resolve, reject) =>
      this.orderService
        .create(marketId, addressId, data)
        .then((resultData: any) => {
          resolve(resultData?.entity);
        })
        .catch(reject),
    );
  }

  orderUpdate(
    orderId: number,
    addressId: number,
    skus: Cart.Order[],
    vouchers: { code: string }[],
  ) {
    const query = `mutation {
      orderUpdate(input:{
        orderId:${orderId},
        addressId:${addressId},
        skus:${JSON.stringify(skus).replace(/"/g, '')},
        applyVouchers:${JSON.stringify(vouchers).replace(/"code"/g, 'code')},
      }) {order {
        discount
        originSubtotalPrice
        subtotalPrice
        totalPrice
      }}
    }`;

    return fetch(`${legalizeApiUrl}/graphql`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', authorization: this.authorization },
      body: JSON.stringify({ query }),
    }).then((res) => res.json());
  }

  orderCheckout(orderId: number, timeslotInfo: any) {
    return new Promise((resolve, reject) =>
      this.orderService
        .checkout(orderId, PaymentMethod.CASH, DeliveryType.SCHEDULED, timeslotInfo)
        .then((resultData: any) => {
          if (resultData) {
            this.productsQuantity.write({});
          }

          resolve(resultData);
        })
        .catch(reject),
    );
  }

  orderCancel(orderId: number) {
    return this.orderService.cancel(orderId);
  }

  getCart() {
    return this.meService.getCartId();
  }

  getProductIds() {
    return this.productIdsStorage.read() || {};
  }

  getPlaceAutocomplete(search: string) {
    return this.placeService
      .search(search, this.sessionId)
      .then((res) => res.map((item) => item.entity));
  }

  getPlaceDetails(placeId: string) {
    return this.placeService.getDetailsByPlaceId(placeId).then((res) => res.entity || {});
  }

  getDefaultPlace() {
    return this.deliveryStorage.read()?.placeDetails;
  }

  setCrossSale(data: any): void {
    this.crossSaleService.write(data);
  }

  getCrossSale(): any {
    return Promise.resolve(this.crossSaleService.read());
  }

  updateDelivery = (data: any) => {
    return Promise.resolve(
      this.deliveryStorage.write({
        ...this.deliveryStorage.read(),
        deliveries: [],
        destinations: [],
        placeDetails: data,
      }),
    );
  };
}

export default CartModel;
