import { isBrowser } from 'browser-or-node';
import { Module } from 'core/framework';

import DeliveryModel from './model/DeliveryModel';
import DeliveryView from './view/DeliveryView';
import DeliveryPresenter from './presenter/DeliveryPresenter';
import DeliveryStore from './presenter/DeliveryStore';
import StorageService from 'core/services/storageService/StorageService';

const presenter = new DeliveryPresenter({
  dependencies: {
    model: new DeliveryModel(),
    store: new DeliveryStore(),
  },
  StorageService: new StorageService(
    isBrowser ? window.sessionStorage : undefined,
    'shop/delivery',
  ),
});

export default Module(presenter, DeliveryView);
