import React, { useState, useEffect } from 'react';
import classnames from 'classnames';

import OrderAnalytics from 'core/framework/analytic/OrderFlow';

import stylesModule from '../Cart.module.scss';

interface ICartProps {
  quantity: number;
  maxQuantity: number;
  onChange: (p: number) => void;
  name: string;
}

const InputQuantity: React.FC<ICartProps> = ({ quantity, onChange, maxQuantity, name }) => {
  const [productQuantity, setProductQuantity] = useState(+quantity);
  const [quantityBeforeEdit, setQuantityBeforeEdit] = useState(0);

  useEffect(() => {
    if (quantity !== productQuantity) {
      onChange(+productQuantity);
    }
  }, [productQuantity]);

  useEffect(() => {
    if (quantity !== productQuantity) {
      checkValidQuantity(quantity);
    }
  }, [quantity]);

  const checkValidQuantity = (value: number) => {
    if (value >= 0) {
      if (value <= maxQuantity) {
        setProductQuantity(value);
      } else {
        setProductQuantity(maxQuantity);
      }
    } else {
      setProductQuantity(0);
    }
  };

  const calculateQuantityChange = () => {
    let change_quantity = productQuantity - quantityBeforeEdit;
    if (change_quantity > 0) {
      OrderAnalytics.addToCartQuantity(name, change_quantity);
    }
    if (change_quantity < 0) {
      OrderAnalytics.removeFromCartQuantity(name, Math.abs(change_quantity));
    }
  };

  return (
    <div className={stylesModule.cartInputBlock}>
      <div
        className={classnames(stylesModule.inputButton, {
          [stylesModule.disabled]: productQuantity === 0,
        })}
        onClick={() => {
          checkValidQuantity(productQuantity - 1);
          OrderAnalytics.removeFromCart(name);
        }}
      >
        <svg
          width="11"
          height="2"
          viewBox="0 0 11 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M0 0H10.68V1.56H0V0Z" />
        </svg>
      </div>
      <input
        value={productQuantity}
        onChange={(e) => checkValidQuantity(+e.target.value)}
        type="number"
        min="0"
        max={maxQuantity}
        onBlur={() => calculateQuantityChange()}
        onFocus={() => setQuantityBeforeEdit(productQuantity)}
      />
      <div
        className={classnames(stylesModule.inputButton, {
          [stylesModule.disabled]: productQuantity >= maxQuantity,
        })}
        onClick={() => {
          checkValidQuantity(productQuantity + 1);
          OrderAnalytics.addToCart(name);
        }}
      >
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.56H4.56V0H6.12V4.56H10.68V6.12H6.12V10.68H4.56V6.12H0V4.56Z"
          />
        </svg>
      </div>
    </div>
  );
};

export default InputQuantity;
