import React from 'react';
import classnames from 'classnames';

import { icons, titles, buttonTitles } from './staticData';

import styles from './DeliveryTypeItem.module.scss';

interface IDeliveryTypeItemProps extends Delivery.DeliveryItem {
  select(): void;
  withButton: boolean;
}

const DeliveryTypeItem: React.FC<IDeliveryTypeItemProps> = (props) => {
  const { id, description, withButton = false, select } = props;

  return (
    <div
      className={classnames(styles.delivery, {
        [styles.withButton]: withButton,
      })}
      onClick={withButton ? () => {} : select}
    >
      <div className={styles.icon}>{icons[id]}</div>
      <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: titles[id] }} />
      {withButton && (
        <button className={styles.button} onClick={select}>
          {buttonTitles[id]}
        </button>
      )}
    </div>
  );
};

export default DeliveryTypeItem;
