import React from 'react';

import { DeliveryServiceTypes } from 'modules/shop/delivery/enums';

export const icons = {
  [DeliveryServiceTypes.deliverByClick]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="85" fill="#fff">
      <path
        fillRule="evenodd"
        d="M43.4 64c1.7 1 3.7 1.5 5.6 1.5 4.5 0 8.4-2.7 10-6.6.8-1.5 2.5-2.1 4-1.3l7.8 4.5 3.8 2.2a3 3 0 01.8 4.2 41.3 41.3 0 01-15.6 11.9l-.3.2c-5.3 2.3-11 3.4-17.7 3.4-11 0-21.3-4.2-28.9-11.8A42 42 0 011 42.2v-.5a42 42 0 0112-29.9A40.2 40.2 0 0141.7 0c6.7 0 12.4 1.1 17.7 3.4l.3.2c1.9.8 10.2 4.6 15.6 11.9a3 3 0 01-.8 4.2l-11.3 6.5c-1.4.8-3.3.2-4-1.4a11.2 11.2 0 00-16-5.6h-.1l-.3.2-.2.1-.2.2-.3.2a67.4 67.4 0 01-18.7 11l-.3.2H23l-.3.2-.3.2a11.6 11.6 0 000 20l.2.3.3.2.5.2.3.1a63.3 63.3 0 0118.7 11l.2.2c.2.1.3.3.5.3l.3.2zm5.2-40c2.3 0 4.3 2 4.3 4.8A89.2 89.2 0 0053 55v.3c0 2.8-2 4.8-4.3 4.8a4 4 0 01-2-.6 79.1 79.1 0 00-20-13l-.4-.2a5.1 5.1 0 010-8.3h.1a69 69 0 0020-13.2h.2c.7-.5 1.4-.7 2.1-.7z"
        clipRule="evenodd"
      />
    </svg>
  ),
  [DeliveryServiceTypes.deliveryService]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="157" height="84" fill="#fff">
      <path
        fillRule="evenodd"
        d="M140.57 21H124.7V0H50.6A10.57 10.57 0 0040 10.5V30H23a4 4 0 000 8h17v7H13a4 4 0 000 8h27v7H4a4 4 0 000 8h36v.25h10.59A15.8 15.8 0 0066.47 84a15.8 15.8 0 0015.88-15.75h31.76A15.8 15.8 0 00129.99 84a15.8 15.8 0 0015.88-15.75h10.59V42l-15.89-21zm-74.1 55.13a7.9 7.9 0 01-7.94-7.88 7.9 7.9 0 017.94-7.88 7.9 7.9 0 017.94 7.88 7.9 7.9 0 01-7.94 7.88zM148.3 42l-10.37-13.13h-13.24V42h23.61zM130 76.13a7.9 7.9 0 01-7.94-7.88 7.9 7.9 0 017.94-7.88 7.9 7.9 0 017.94 7.88 7.9 7.9 0 01-7.94 7.88z"
        clipRule="evenodd"
      />
    </svg>
  ),
  [DeliveryServiceTypes.byAtStore]: (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="84" fill="#fff">
      <path
        fillRule="evenodd"
        d="M59.4 29.7c0 5.6-1.5 10.9-4.3 15.4h.2L29.7 84 4 45h.2a29.7 29.7 0 1155.1-15.4zM29.7 18.4a11.3 11.3 0 100 22.6 11.3 11.3 0 000-22.6z"
        clipRule="evenodd"
      />
    </svg>
  ),
};

export const titles = {
  [DeliveryServiceTypes.deliverByClick]: 'Get CLICK<br>delivered',
  [DeliveryServiceTypes.deliveryService]: 'delivery<br>service',
  [DeliveryServiceTypes.byAtStore]: 'buy at<br>store',
};

export const buttonTitles = {
  [DeliveryServiceTypes.deliverByClick]: 'Order Now',
  [DeliveryServiceTypes.deliveryService]: 'buy now',
  [DeliveryServiceTypes.byAtStore]: 'store list',
};
