/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default {
  container: (provided: any) => ({
    ...provided,
    marginTop: '0vw',
    border: '1px solid #CFCFCF',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none',
    borderRadius: 0,
    outline: 0,
    minHeight: '56px',
    backgroundColor: state.selectProps.background || '#ffffff',
    textTransform: 'uppercase',
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    color: '#626262',
    cursor: 'pointer',
    fontSize: '0.875rem',
    ':hover': {
      backgroundColor: '#f2f2f2',
    },
    '@media (max-width: 500px)': {
      fontSize: '13px',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'Gotham',
    color: '#626262',
    fontSize: '0.875rem',
    '@media (max-width: 500px)': {
      fontSize: '13px',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    outline: 0,
    marginBottom: '20px',
    fontFamily: 'Gotham',
    fontSize: '14px',
    color: '#626262',
    '@media (max-width: 850px)': {
      marginRight: '20px',
    },
    '@media (max-width: 500px)': {
      fontSize: '13px',
    },
    textAlign: 'left',
  }),
  input: (provided: any) => ({
    ...provided,
    width: '100%',
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    borderRadius: 0,
    '@media (max-width: 850px)': {
      marginTop: '5px',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};
