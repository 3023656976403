import React from 'react';

import styles from './Success.module.scss';

interface ISuccessProps {
  email: string;
}

const Success = ({ email }: ISuccessProps) => (
  <div className={styles.cart}>
    <div className={styles.body}>
      Hey There, please check your inbox!
      <br />
      We just sent an email to <br />
      {email}
      <br />
      to get you updated with the delivery process.
    </div>
  </div>
);

export default Success;
