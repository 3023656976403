import { Store } from 'core/framework';
import { ClickTypes } from 'services/enums';

const initialState: Cart.StoreState = {
  activeType: ClickTypes.restore,
  deliveryData: {},
  setAddress: false,
};

class CartStore extends Store<Cart.StoreState> {
  constructor() {
    super(initialState);
  }

  changeActiveType(state: Cart.StoreState, payload: Cart.StoreActionPayload) {
    return {
      ...state,
      activeType: payload.activeType,
    };
  }

  setDeliveryAddress(
    state: DeliveryAddress.StoreState,
    payload: DeliveryAddress.StoreActionPayload,
  ) {
    return {
      ...state,
      deliveryData: payload.data,
      setAddress: true,
    };
  }
}

export default CartStore;
